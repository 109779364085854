import car1 from '../assets/images/cars/showroom/platinum/1.jpg'
import car2 from '../assets/images/cars/showroom/platinum/2.jpg'
import car3 from '../assets/images/cars/showroom/platinum/3.jpg'
import car4 from '../assets/images/cars/showroom/platinum/4.jpg'
import car5 from '../assets/images/cars/showroom/platinum/5.jpg'
import car6 from '../assets/images/cars/showroom/platinum/6.jpg'
import car7 from '../assets/images/cars/showroom/platinum/7.jpg'
import car8 from '../assets/images/cars/showroom/platinum/8.jpg'
import car9 from '../assets/images/cars/showroom/platinum/9.jpg'
import car10 from '../assets/images/cars/showroom/platinum/10.jpg'
import car11 from '../assets/images/cars/showroom/platinum/11.jpg'
import car12 from '../assets/images/cars/showroom/platinum/12.jpg'
import car13 from '../assets/images/cars/showroom/platinum/13.jpg'
import car14 from '../assets/images/cars/showroom/platinum/14.jpg'
import car15 from '../assets/images/cars/showroom/platinum/15.jpg'

export default {
  1: car1,
  2: car2,
  3: car3,
  4: car4,
  5: car5,
  6: car6,
  7: car7,
  8: car8,
  9: car9,
  10: car10,
  11: car11,
  12: car12,
  13: car13,
  14: car14,
  15: car15,
}
