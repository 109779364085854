import car1 from '../assets/images/cars/showroom/special-editions/1.jpg'
import car2 from '../assets/images/cars/showroom/special-editions/2.jpg'
import car3 from '../assets/images/cars/showroom/special-editions/3.jpg'
import car4 from '../assets/images/cars/showroom/special-editions/4.jpg'
import car5 from '../assets/images/cars/showroom/special-editions/5.jpg'
import car6 from '../assets/images/cars/showroom/special-editions/6.jpg'
import car7 from '../assets/images/cars/showroom/special-editions/7.jpg'
import car8 from '../assets/images/cars/showroom/special-editions/8.jpg'
import car9 from '../assets/images/cars/showroom/special-editions/9.jpg'
import car10 from '../assets/images/cars/showroom/special-editions/10.jpg'

export default {
  1: car1,
  2: car2,
  3: car3,
  4: car4,
  5: car5,
  6: car6,
  7: car7,
  8: car8,
  9: car9,
  10: car10,
}
